/* eslint react/prop-types: 0 */
import { Fragment, useEffect } from 'react'

import { ThemeProvider } from '@emotion/react'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import jsonParse from 'destr'
import Cookies from 'js-cookie'
import { NextComponentType, NextPage, NextPageContext } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Provider as ReduxProvider, useStore } from 'react-redux'
import { Action, AnyAction, Store as ReduxStore } from 'redux'
import { Persistor } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { lightTheme } from '@/theme'
import renderSegmentSnippet from '@components/head/segment'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS, splitConfig } from '@helpers/constants'
import crossBrowserListener from '@helpers/reduxpersist-listener'
import { BaseStyles, LegacyStyles } from '@microcomponents/global/global-styles'
import { initAdjust, page } from 'analytics'
import { SplitContext } from 'context/split'
import { captureAnonId } from 'helpers/error-handler'
import { GlobalStyles } from 'microcomponents/global'
import { persistConfig, reduxWrapper } from 'store'

// import enhancedPerformanceMetric from 'helpers/performance'
// Disabling this but leaving the code present.  As of June 2021 we are not actively using the perf data that we are collecting.
// Additionally, we have multiple other tools collecting related data, including split.io, fullstory, and Google Analytics.
// The `/performance` endpoint is still enabled, to re-enable collecting these metrics uncomment the code below.
// When re-enabling, you should also add `DISABLE_PERF_LOGGING=true` to npm scripts used in E2E and local dev.
// export function reportWebVitals (metric) {
//   if (!DISABLE_PERF_LOGGING) {
//     const body = enhancedPerformanceMetric(metric)
//     const url = '/performance'
//     // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
//     if (navigator.sendBeacon) {
//       navigator.sendBeacon(url, body)
//     } else {
//       fetch(url, { body, method: 'POST', keepalive: true })
//     }
//   }
// }

// The PersistGate component is the recommended way to delay rendering until persistence is complete.
// You can provide a loading prop to PersistGate: The provided loading value will be rendered until persistence is complete at which point children will be rendered.

interface Store<S = any, A extends Action = AnyAction> extends ReduxStore<S, A> {
  __persistor: Persistor
}

interface MyAppProps extends AppProps {
  Component: NextComponentType<NextPageContext, any, any>
  pageProps: any
}

const MyApp: NextPage<MyAppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const store = useStore() as Store

  useEffect(() => {
    initAdjust()
  }, [])

  useEffect(() => {
    let splitUserId = pageProps.eazeAnonId
    if (!splitUserId) {
      const segmentAnonCookie = Cookies.get('ajs_anonymous_id')
      if (segmentAnonCookie) splitUserId = jsonParse(segmentAnonCookie)
    }

    if (splitUserId) {
      captureAnonId(splitUserId) // capture anon_id for Sentry
    }

    const handleRouteChange = (url: string) => {
      page(url)
      // Identify on navigation from one page to another like /menu to /groups
      if (splitUserId) {
        window && window.SPLIT_RUM && window.SPLIT_RUM.identities([{ key: splitUserId, trafficType: 'anonymous' }])
      }
    }
    // Identify on page mounts like direct loads and refreshes
    if (splitUserId) {
      window && window.SPLIT_RUM && window.SPLIT_RUM.identities([{ key: splitUserId, trafficType: 'anonymous' }])
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, pageProps.eazeAnonId])

  if (typeof window !== 'undefined') {
    // executes when localStorage changes with state data
    window.addEventListener('storage', crossBrowserListener(store, persistConfig))
  }

  return (
    <Fragment>
      {/* Lightbox Javascript for digioh.com campaigns */}
      <Script src="https://www.lightboxcdn.com/vendor/8197a3b8-21b0-4793-a6b2-23649fbb79d0/lightbox_inline.js" />
      {/* Load segment for analytic tracking events */}
      <Script
        id="segment"
        dangerouslySetInnerHTML={{
          __html: renderSegmentSnippet(pageProps?.eazeAnonId)
        }}
      />
      {pageProps?.splitTreatments?.[SPLIT_EXPERIMENTS.REDESIGN]?.treatment === SPLIT_TREATMENTS.REDESIGN.ON ? (
        <GlobalStyles styles={BaseStyles} />
      ) : (
        <GlobalStyles styles={LegacyStyles} />
      )}
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={store.__persistor}>
          {() => (
            <SplitFactoryProvider config={splitConfig}>
              {() => {
                return (
                  <SplitContext.Provider value={{ ...pageProps.splitTreatments }}>
                    <ThemeProvider theme={lightTheme}>
                      <Component {...pageProps} />
                    </ThemeProvider>
                  </SplitContext.Provider>
                )
              }}
            </SplitFactoryProvider>
          )}
        </PersistGate>
      </ReduxProvider>
    </Fragment>
  )
}

export default reduxWrapper.withRedux(MyApp)
