/**
 * List types of alert visualizations
 */
export const BANNER = 'BANNER'
export const MODAL = 'MODAL'

/**
 * List all types of actions that alerts would prevent
 */
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const ADD_TO_CART = 'ADD_TO_CART'
export const CHANGE_PROFILE = 'CHANGE_PROFILE'
export const HAS_LINKED_ACCOUNTS_DETECTED = 'HAS_LINKED_ACCOUNTS_DETECTED'
export const BLOCK_UPLOAD_ID = 'BLOCK_UPLOAD_ID'
export const CHECKOUT = 'CHECKOUT'
export const PLACE_ORDER = 'PLACE_ORDER'

/**
 * List all query params that we might want to account for re: showing/hiding of banners/modals
 */
export const CART = 'cart'
export const HELP = 'help'
export const ORDER_HISTORY = 'orderHistory'

/**
 * List all possible types of alerts
 */
export const alertTypes = {
  AGE_GATE: 'AGE_GATE',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  AT_DAILY_LIMIT: 'AT_DAILY_LIMIT',
  CANCELED: 'CANCELED',
  CART_EMPTY: 'CART_EMPTY',
  CART_EXPIRED: 'CART_EXPIRED',
  CART_SIZE_MISMATCH: 'CART_SIZE_MISMATCH',
  CHANGED_PASSWORD: 'CHANGED_PASSWORD',
  CHECKOUT: 'CHECKOUT',
  CHECKOUT_MESSAGE: 'CHECKOUT_MESSAGE',
  COLLECTIVE_AGREEMENT_NOT_SIGNED: 'COLLECTIVE_AGREEMENT_NOT_SIGNED',
  CREDIT: 'CREDIT',
  CASH_ONLY: 'CASH_ONLY',
  DELIVERY_DISCOUNT_MINIMUM: 'DELIVERY_DISCOUNT_MINIMUM',
  DEPOT_MISMATCH: 'DEPOT_MISMATCH',
  DISCOUNTED_DELIVERY_BYPASS: 'DISCOUNTED_DELIVERY_BYPASS',
  DISCOUNTED_DELIVERY_FEE: 'DISCOUNTED_DELIVERY_FEE',
  ETA_LOADING: 'ETA_LOADING',
  ETA_LOADING_ERROR: 'ETA_LOADING_ERROR',
  EXISTING_EMAIL_ADDRESS: 'EXISTING_EMAIL_ADDRESS',
  EXISTING_PHONE_NUMBER: 'EXISTING_PHONE_NUMBER',
  EXPIRED_ID: 'EXPIRED_ID',
  EXPIRED_REC: 'EXPIRED_REC',
  FREE_DELIVERY: 'FREE_DELIVERY',
  ID_DECLINED: 'ID_DECLINED',
  ID_PENDING: 'ID_PENDING',
  ID_REQUIRED: 'ID_REQUIRED',
  ID_INVALID_IN_AREA: 'ID_INVALID_IN_AREA',
  IMAGE_UPLOAD_ERROR: 'IMAGE_UPLOAD_ERROR',
  INVALID_LOGIN: 'INVALID_LOGIN',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_STREET_ADDRESS: 'INVALID_STREET_ADDRESS',
  LOCATION: 'LOCATION',
  LOCATION_OUT_OF_SERVICE: 'LOCATION_OUT_OF_SERVICE',
  LOGIN_OR_SIGNUP_ERROR: 'LOGIN_OR_SIGNUP_ERROR',
  NEED_TO_SHOW_ID: 'NEED_TO_SHOW_ID',
  NEED_2FA_REAUTH: 'NEED_2FA_REAUTH',
  NO_DRIVERS: 'NO_DRIVERS',
  NO_PAYMENT_SELECTED: 'NO_PAYMENT_SELECTED',
  NON_EXISTENT_EMAIL: 'NON_EXISTENT_EMAIL',
  PHONE_VERIFICATION_INCOMPLETE: 'PHONE_VERIFICATION_INCOMPLETE',
  PRODUCTS_UNAVAILABLE: 'PRODUCTS_UNAVAILABLE',
  REMOVED_PROMO: 'REMOVED_PROMO',
  SAMPLE_MENU: 'SAMPLE_MENU',
  STORE_CLOSED: 'STORE_CLOSED',
  STORE_CLOSED_SCHEDULED_DELIVERY_ON: 'STORE_CLOSED_SCHEDULED_DELIVERY_ON',
  STORE_CLOSING_SOON: 'STORE_CLOSING_SOON',
  UNDER_AGE_MINIMUM: 'UNDER_AGE_MINIMUM',
  UNDER_MINIMUM: 'UNDER_MINIMUM',
  VERIFICATION_DECLINED: 'VERIFICATION_DECLINED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  VERIFICATION_PROCESS_ERROR: 'VERIFICATION_PROCESS_ERROR',
  VERIFICATION_RESEND_SUCCESSFUL: 'VERIFICATION_RESEND_SUCCESSFUL',
  VERIFY_PHONE: 'VERIFY_PHONE',
  VERSION_MISMATCH: 'VERSION_MISMATCH',
  HAS_LINKED_ACCOUNTS: 'HAS_LINKED_ACCOUNTS'
} as const

export type Alerts = keyof typeof alertTypes
